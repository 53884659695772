<template>
    <v-app>
        <v-card>
            <v-card-title>
                <span>Ordenes de compra</span>
                <v-spacer></v-spacer>
                <v-btn class="" @click="fetchData"  text icon color="blue">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="tableData"
                    :search="search"
                    v-model="selected"
                    no-results-text="No hay resultados disponibles"
                    no-data-text="No hay datos disponibles"
                    loading-text="Cargando datos..."
                    :sort-by="'id'"
                    :sort-desc="true"
                    class="elevation-1 datatable"
                >
                    
                    <template v-slot:item.purchase_order_reference="{ item }">
                        <td class="text-center">
                            <a :href="'#/oc/'+item.purchase_order_reference" >{{item.purchase_order_reference}}</a>
                            
                        </td>
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        <td class="text-center">
                            {{ $moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a') }}
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
    data()
    {
        return {
            headers: [
                {text: 'ID', value: 'id'},
                {text: 'OC', value: 'purchase_order_reference'},
                {text: 'Fecha creación', value: 'created_at'},
            ],
            tableData: [],
            search: '',
            selected: []
        }
    },
    created(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Ordenes de compra ", route: "/ocs/" },
        ]);
    },
    mounted()
    {
        this.fetchData();
    },
    methods: {
        fetchData()
        {
            this.axios({
                method: 'POST',
                url: 'inventory/ocs',
                data: {
                    store: this.$store.state.passport.user.default_store_id
                }
            }).then( response => {
                this.tableData = response.data;
            }).catch( error => {
                console.log(error);
            });
        }
    },
}
</script>